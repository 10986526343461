.containerProfesores {
  padding-left: 4%;
  padding-right: 4%;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.cardGroupProfesores {
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.loadingProfesoresAnuncio {
  text-align: center;
  padding-top: 100px;
}

.cardImagemProfesoresAnuncio {
  width: 16rem;
  height: 20vw;
  object-fit: cover;
  padding-top: 65px;
  padding-left: 10px;
}

.cardImagemProfesoresAnuncioMobile {
  width: 100%;
  height: 60vw;
  object-fit: cover;
}
