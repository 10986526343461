.containerProfesionalesInfo {
  padding-top: 3%;
  padding-bottom: 3%;
  padding-right: 12%;
  padding-left: 12%;
  text-align: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.imagemProfesionalesInfo {
  width: 50%;
  height: 20vw;
  object-fit: cover;
}

.imagemProfesionalesInfoMobile {
  width: 100%;
  height: 60vw;
  object-fit: cover;
}

.containerProfesionalesInfo p {
  font-size: 20px;
}

.containerProfesionalesInfo h2 {
  font-weight: bold;
}

.carousel-indicators .active {
  background-color: #693B00;
}

.carousel-control-next, .carousel-control-prev {
  filter: invert(100%);
}
