.containerNew {
  padding-top: 1px;
  padding-bottom: 5%;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  min-height: 721px;
}

form#new {
  width: 100%;
  max-width: 680px;
  margin: 30px auto 0;
  padding: 30px;
  background: #FFF;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
}

form#new a {
  text-align: center;
  text-decoration: none;
  color: #272727;
  font-size: 15px;
}

div .register-h1 {
  padding-bottom: 2%;
  text-align: center;
}

.cardImagemEditar {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.loadingNewPost {
  text-align: center;
  padding-top: 100px;
}

@media screen and (max-width: 1300px) {
  .containerNew {
    padding-top: 0%;
    min-height: 0px;
  }
}