.containerProfesionales {
  min-height: 630px;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  padding-top: 5px;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.profesionalesHover {
  padding: 10px
}

.profesionalesHover:hover {
  transform: translateY(-1%);
  outline: 1px solid #A36920;
  background: #A36920;
}

.cardProfesionales {
  width: 15rem;
  min-height: 620px;
  max-height: 620px;
  background-color: #DCBA8C
}

.cardImagemProfesionales {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.cardImagemProfesionalesMobile {
  width: 100%;
  height: 70vw;
  object-fit: cover;
}

.cardProfesionalesTitulo {
  background-color: #A36920;
  color: white;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  min-height: 60px;
  max-height: 60px;
  padding: 10px
}

.cardImagemProfesionalesNome {
  min-height: 40px;
  max-height: 40px;
  font-weight: bold;
  color: black;
  text-align: center
}

.cardImagemProfesionalesPaisCidade {
  color: black;
  text-align: center;
}

.cardImagemProfesionalesResumo {
  min-height: 120px;
  color: white;
  text-align: center
}

.loadingProfesionales {
  text-align: center;
  padding-top: 100px;
}

.CardGroupProfesionales {
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

@media screen and (max-width: 1200px) {
  .cardImagemProfesionales {
    width: 100%;
    height: 25vw;
    object-fit: cover;
  }
}