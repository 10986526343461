.containerHome {
  padding-top: 0%;
}

.textTopHome {
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
}

.cardHome {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 1%;
  padding-bottom: 1%;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.descricaoHome {
  max-width: 350px;
  max-height: 120px;
  min-height: 120px;
}

@media screen and (max-width: 1300px) {
  .cardHome {
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .descricaoHome {
    max-width: none;
    max-height: none;
    min-height: none;
  }
}