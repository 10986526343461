.containerPrivacidad {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 10%;
  padding-left: 10%;
  text-align: justify;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

@media screen and (max-width: 1000px) {
  .containerPrivacidad {
    padding-top: 0%;
    padding-bottom: 0%;
    padding-right: 5%;
    padding-left: 5%;
  }
}