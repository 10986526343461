.containerFooter {
  background-image: linear-gradient( to right, #DCBA8C, #DCBA8C, #DCBA8C, #DCBA8C, #DCBA8C, #DCBA8C);
}

.logoFooter {
  text-align: left;
}

.imagemFooter {
  width: 8vw;
  height: 8vw;
  object-fit: cover;
}

.imagemFooterMobile {
  width: 20vw;
  height: 20vw;
  object-fit: cover;
}

.imagemMedalla {
  padding-right: 60px;
}

.imagemMedallaMobile {
  padding-right: none;
}

@media screen and (max-width: 1300px) {
  .logoFooter {
    text-align: center;
  }
}