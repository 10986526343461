.containerHeader {
  background-image: linear-gradient( to right, #DCBA8C, #DCBA8C, #DCBA8C, #DCBA8C, #DCBA8C, #DCBA8C);
}

.imagemHeader {
  width: 12vw;
  height: 12vw;
}

.imagemHeaderMobile {
  width: 20vw;
  height: 20vw;
  object-fit: cover;
}

.dropbtn {
  padding: 16px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  font-family: 'Open Sans', sans-serif;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 1295px) {
  .dropdown:hover .dropdown-content {
    display: contents;
  }
}

