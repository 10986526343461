.containerInfoMusicInfo {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 10%;
  padding-left: 10%;
  text-align: justify;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  background: url(../img/FondologoinfoMusic.png);
  background-size: 400px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.containerInfoMusicInfo h2 {
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .containerInfoMusicInfo {
    padding-right: 5%;
    padding-left: 5%;
  }
}