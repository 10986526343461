.containerRemover {
  padding-top: 1px;
  padding-bottom: 5%;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  min-height: 721px;
}

.remover {
  width: 100%;
  max-width: 700px;
  margin: 30px auto 0;
  padding: 30px;
  background: #FFF;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
}

.loadingRemover {
  text-align: center;
  padding-top: 100px;
}


@media screen and (max-width: 1300px) {
  .containerRemover {
    padding-top: 0%;  
  }
}

