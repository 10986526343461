.containerDashboard {
  padding-top: 1px;
  padding-bottom: 5%;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  min-height: 721px;
}

.dashboard {
  width: 100%;
  max-width: 980px;
  margin: 30px auto 0;
  padding: 30px;
  background: rgb(255, 255, 255);
  border: 1px solid #DDD;
  /* display: flex;
  flex-direction: column; */
}

.botoesDashboard {
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background: #272727;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.imagemDashboard {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20vw;
}

@media screen and (min-width: 1100px) {
  .imagemDashboard {
    width: 5vw;
  }
}

@media screen and (max-width: 1300px) {
  .containerDashboard {
    padding-top: 0%;
    min-height: 0px;
  }
  .dashboard {
    padding: 0px;
  }
}